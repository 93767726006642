*,
*::before,
*::after {
    box-sizing: border-box;
}

.main-container {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafafb;
    background-image: url("../../Assets/images/pre-sales-BG.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

/* .main-container2 {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafafb;
    background-image: url("../../Assets/images/presalesBG-01.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
} */

.content{
    padding: 15%;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contentlogin {
    height: auto;
    width: auto;
    padding: 10px;
    border-radius: 6px;
    background-color: #ffffff;  
    overflow: hidden;
    box-shadow: 4px 12px 40px 6px rgb(0 0 0 / 9%);
}

.absyz-1{
    width:100%;
    display: flex;
    justify-content: center;
}

.absyz {
    width: 50%;
    
}

.absyzimg {
    height: 40px;
    vertical-align: middle;
}
.absyzimg2 {
    height: 70px;
    vertical-align: middle;
}

.logo-wrapper2 {
    display: flex;
    justify-content: center;
    font-size: 60px;
    font-weight: bold;
    margin: 0px 5px;
}
.logo-wrapper {
    display: flex;
    justify-content: center;
    font-size: 28px;
    font-weight: bold;
    margin: 0px 5px;
}

.bg-blue {
    color: #008fc4;
}

.button-1 {
    background-color: #62AF7B !important;
    width: 50%;

}

.button-2 {
    background-color: transparent !important;
    color: #0B2663;
    width: 50%;
    font-weight: 600 !important;
    font-size: 14px !important;
}

.button-2:hover::after {
    opacity: 1;
    right: 0;
}

.button-2::after {
    content: "?" !important;
    font-size: 26px !important;
    position: absolute !important;
    opacity: 0 !important;
    top: 0 !important;
    right: -15px !important;
    transition: 0.5s !important;
}

.grid-1 {
    flex-grow: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
}

.icon {
    font-size: 70px;
    color: #54BAB9;
}

.home-heading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 15px;
    color: #006dcc;
    font-size: 22px;
    margin-top: 5px;
    margin-bottom: 10px;
}

.home-description {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 15px;
    text-align: center;
    color: #585858;
    font-size: 16px;
    height: 100px;
}

.home-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: inherit;
}

.home-button-1 {
    color: #1572A1 !important;
    background-color: #84bd00 !important;
    color: white !important;
    border: 1px solid #84bd00 !important;
    min-width: 170px !important;
}

.home-top-border {
    background-color: #84bd00;
    height: 8px;
    width: 100%;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.css-1et7qun-MuiGrid-root>.MuiGrid-item {
    padding-top: 0px !important;

}

.css-1twpwdm>.MuiGrid-item {
    padding-top: 64px !important;
    padding-bottom: 0px !important;
}

.forgotpassword {
    font-size: 14px !important;
}

.css-1twpwdm>.MuiGrid-item {
    padding-top: 47px !important;
}

.css-zzhupx {
    background-color: white !important;
    color: black !important;
}

.css-1x5jdmq{
    color: black !important;
}

#admin-h {
    height: 81vh !important;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
}

@media screen and (min-width: 1400px) {
    #admin-h {
        height: 89.1vh !important;
        display: flex !important;
        flex-direction: column;
        justify-content: center;
    }

}

.Admin-cards .css-4kcope-MuiGrid-root>.MuiGrid-item {
    padding-top: 60px !important;
}
.home2-bg{
    background-image: url("../../Assets/images/Pre-Sales Home BG.png");
    width: 100%;
    /* background-repeat: no-repeat; */
    /* background-position:center; */
    background-size: cover;
    /* background-position: ; */
}
.home2_card1{
    background-image: url("../../Assets/images/Rectangle\ 2.png");
     height: 19%;
     width: 95%
 }
 
 .home2_card2{
     background-image: url("../../Assets/images/Rectangle\ 2.png");
     height: 19%;
     width: 95%
 
  }
  .home2_card3{
     background-image: url("../../Assets/images/Rectangle\ 2.png");
     height: 19%;
     width: 95%
  }