.pre-sales{
    background: linear-gradient(to right bottom,#1f224b 0%,#2d5d85 100%);
    color: white;
}



.Button-butter{
    display: inline-block;
    border-radius: 4px;
    background-color: #282936;
    border: none;
    min-width: 100px;
    color: #fff;
    text-align: center;
    font-size: 15px;
    padding: 5px 10px;
    width: auto;
    transition: all .5s;
    cursor: pointer;
    margin: 5px;
}

.Button-butter-1{
    display: inline-block;
    border-radius: 4px;
    background-color: #282936;
    border: none;
    min-width: 100px;
    color: #fff;
    text-align: center;
    font-size: 15px;
    padding: 5px 10px;
    width: auto;
    transition: all .5s;
    cursor: pointer;
    margin: 5px;
}

.header{
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
}

.header-1{
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
}

.header-2{
   
    font-size: 20px;
    font-weight: 500;
}

.content-2{
    width: 100%;
    max-width: 500px;
    margin: auto;
    padding: 30px 30px;
    height: 77vh;
}
.content-3{
    width: 100%;
    max-width: 500px;
    margin: auto;
    padding: 30px 30px;
    height: 74vh;
}   
.content-4
{
    width: 100%;
    max-width: 500px;
    margin: auto;
    padding: 30px 30px;
    height: 74vh;
}
@media screen and (min-width:1400px) {
    .content-4
    {
        height: 85vh;
    }
}
@media screen and (min-width:1400px) {
    .content-2{
       height: 87.2vh; 
    }
}
@media screen and (min-width:1400px) {
    .content-3{
       height: 85vh; 
    }
}
.tab-image-1{
    height: 110px;
    cursor: pointer;
    min-width: 200px!important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0 7px;
    display: inline-block;
    border: 2px solid #0F2C67;
    border-radius: 6px;
    overflow-x: auto !important;
}

.css-v5xrqr-MuiButtonBase-root-MuiTab-root{
  min-width: 300px!important;
}

.button-text {
    display: inline-block;
    border-radius: 4px;
    background-color:#2ecc71;
    border: none;
    min-width: 100px;
    color: #fff;
    text-align: center;
    font-size: 15px;
    padding: 5px 10px;
    width: auto;
    transition: all .5s;
    cursor: pointer;
    margin: 5px;
    
}
.button-text2 {
    display: inline-block;
    border-radius: 4px;
    background-color:#17a2b8;
    border: none;
    min-width: 100px;
    color: #fff;
    text-align: center;
    font-size: 15px;
    padding: 5px 10px;
    width: auto;
    transition: all .5s;
    cursor: pointer;
    margin: 5px;
   
}
.button-text3
{
    display: inline-block;
    border-radius: 4px;
    background-color:#17a2b8;
    border: none;
    min-width: 100px;
    color: #fff;
    text-align: center;
    font-size: 15px;
    padding: 5px 10px;
    width: auto;
    transition: all .5s;
    cursor: pointer;
    margin: 5px;
}

.content-1{
    width: 100%;
    padding: 35px 30px;
    display: flex;
    justify-content: center;
    height: 398px;
}

.form-div-1{ 
    transition: .5s ease-in!important;
    height: auto;
    width: 109%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 20px 40px;
    border-radius: 6px;
    background-color: #fff;
    -webkit-backdrop-filter: saturate(180%) blur(20px)!important;
    backdrop-filter: saturate(180%) blur(20px)!important;
    overflow: hidden;
    box-shadow: 4px 12px 40px 6px #00000017;
}

.logo-wrapper-1{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
}

.MuiTextField-root {
    margin: 8px;
    width: 42ch !important;
}

.ndfHFb-c4YZDc-cYSp0e, .ndfHFb-c4YZDc-cYSp0e-s2gQvd 
{
    background-color: cadetblue !important;
}

.manage-presentation-box
{
  height: 100%;
}
@media screen and (min-width: 1300px){
    .manage-presentation-box
    {
        height: 84.1vh;
    }
}

.manage-cv-box
{
   height: 100%; 
}

@media screen and (min-width:1400px) {
    .manage-cv-box
    {
        height: 84.1vh;
    }
}

.css-1a4b21o{
    padding: 12px;
}