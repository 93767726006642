.button{
    color: white !important;
    font-size: 16px !important;
    font-weight: 600 !important; 
    margin-left: 25px !important;
  }

  .text{
    font-size: 21px !important;
    font-weight: 600 !important; 
    color: white !important;
  }

  .element_style{
    width: 53.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo-div{
    display:flex
  }

  
  .logo{
    display: flex;
    justify-content: space-between;
    padding-top: 8px;

  }

  .pre-salestext{
    display: block;
    font-size: 24px;
    font-weight: 500;
    color: #3e3e3c;
}

.assistented-proposalText{
    /* padding-top: 7px; */
    display: block;
    font-size: 11px;
    font-weight: 300;
    color: #3e3e3c;
}

.login-box {
  background-color: white;
  /* padding: 1rem; */
  margin: 1rem auto;
  width: 40rem;
  /* height: 400px; */
  max-width: 95%; 
  /* border-radius: 12px; */
  /* text-align: center; */
   box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
}

.pre-sales{
  background: linear-gradient(to right bottom,#1f224b 0%,#2d5d85 100%);
  color: white;
}


.Button-butter{
  display: inline-block;
  border-radius: 4px;
  background-color: #282936;
  border: none;
  min-width: 100px;
  color: #fff;
  text-align: center;
  font-size: 15px;
  padding: 5px 10px;
  width: auto;
  transition: all .5s;
  cursor: pointer;
  margin: 5px;
}

.Button-butter-1{
  display: inline-block;
  border-radius: 4px;
  background-color: #282936;
  border: none;
  min-width: 100px;
  color: #fff;
  text-align: center;
  font-size: 15px;
  padding: 5px 10px;
  width: auto;
  transition: all .5s;
  cursor: pointer;
  margin: 5px;
}

.header{
  margin-top: 10px;
  margin-bottom: 10px;
}

.header-1{
  /* margin-left: 640px; */
  font-size: 20px;
  font-weight: 500;
}

.content-1{
  width: 100%;
  max-width: 750px;
  margin: auto;
  padding: 30px 30px;
}

.css-1yhpg23-MuiTableCell-root
{
    padding: 7px !important;
}

.main-div{
  background-image: url(../../../Assets/images/background-10.png);
  background-size: cover;
}

.tablechange tr:nth-child(odd) {
  background-color: rgb(66, 185, 197);
}
.tablechange tr:nth-child(even) {
  background-color: rgb(23 45 84)
}

.login-box .css-1fw5qro-MuiPaper-root{
  border-radius: 0px !important;
}

.login-box .css-1yft3qs{
  max-height: 366px !important;
}

.login-box .css-1cf86vr{
  border-radius: 0px !important;
}

.login-box .css-dwuj3p-MuiTableCell-root{
  border-bottom: 0px !important;
}

.login-box .css-1yhpg23-MuiTableCell-root{
  border-bottom: 0px !important;
}

.login-box .css-nw3mfz{
  border-bottom: 0px !important;
  padding: 12px !important;
}

.login-box .css-1a4b21o{
  border-bottom: 0px !important;
  padding: 12px !important;
}