.header-tabs .css-1py9u0x-MuiTabs-indicator {
  /* background-color: white !important; */
  display: none !important;
}

.header-tabs .Mui-selected {
  background-color: #1b336d !important;
  color: #ffffff !important;
}

.header-tabs .css-1vw5i9y-MuiButtonBase-root-MuiTab-root {
  min-height: 28px !important;
  padding: 0px !important;
}

.header-tabs{
    min-height: 0px !important;
}

.header-tabs .MuiButtonBase-root {
  /* border: 1px solid #CFD9EC; */
  /* border-right: none; */
  background-color: #ffffff;
  /* padding: 5px 40px; */
  color: #0c2663;
  font-size: 13px;
  font-weight: 500;
}

/* .header-tabs .css-1vw5i9y-MuiButtonBase-root-MuiTab-root {
  min-height: 25px !important;
} */

/* .header-tabs .MuiTabs-scroller{
    margin-top: 12px;
} */

.header-tabs .MuiButtonBase-root:first-of-type {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  margin-left: 5px
}

.header-tabs .MuiButtonBase-root:last-of-type {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  margin-right: 5px;
}

.slidebar {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
/* #tab
{    background-color: #6abde5c2;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    color: white;
    display: inline-flex;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 13px !important;
    font-weight: 500;
    min-width: 160px;
    min-height: 40px;
   
    overflow-x: hidden;
}
.tap{
    border-bottom-right-radius: 10px !important;
   
    background-color: #1B336D !important;
    color: #ffffff !important;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
} */
.css-1aquho2-MuiTabs-indicator {
  background-color: white !important;
  height: 2px !important;
  position: fixed !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  /* min-width: 368px !important; */
  height: 0.4em !important;
}

.right-div {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-top: 18px;
}
.button-down {
  display: inline-block;
  border-radius: 4px;
  background-color: #135790;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 15px;
  padding: 5px 10px;
  transition: all 0.5s;
  cursor: pointer;
  height: 40p;
  margin-left: 37px;
}

.button-down span {
  cursor: pointer;
  font-size: 15px;
  display: inline-block;
  /* position: absolute;  */
  transition: 0.5s;
}

.button-down span:after {
  font-size: 16px;
  /* position: relative; */
  opacity: 0;
  padding-top: 1px;
  margin-left: 3px;
  transition: 0.5s;
}

.button-down:hover span {
  padding-right: 15px;
}

.button-down:hover span:after {
  opacity: 1;
  right: 0;
}

.button-forgot {
  display: inline-block;
  border-radius: 4px;
  background-color: #1c1919;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 15px;
  height: 40px;
  padding: 5px 10px;
  transition: all 0.5s;
  cursor: pointer;
}
.button-forgot span:after {
  content: "?";
  font-size: 15px;
  position: absolute;
  opacity: 0;
  top: 0;
  right: -15px;
  transition: 0.5s;
}
.button-forgot:hover span {
  padding-right: 15px;
}

.button-forgot:hover span:after {
  opacity: 1;
  right: 0;
}

li {
  list-style-type: none;
}

.header-tabs .css-23pgmv {
  min-height: 0px;
}
