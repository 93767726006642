.css-a3xx3e-MuiButtonBase-root-MuiButton-root
{
    margin-right: 5px !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input
{
    padding: 22px 9px !important;
}

.tabs-1{
    height: 81.5vh;
    /* background-color: rgba(66, 185, 197, 1); */
    background-image: url(../../../Assets/images/Rectangle-34.png);
}

.tabs-1 .css-1aquho2-MuiTabs-indicator{
    height: 0px !important;
}

.tabs-1 .css-10d9dml-MuiTabs-indicator{
    width: 0px !important;
} 

.MuiFormControl-root.MuiTextField-root.incommingName.css-i44wyl {
    width: unset!important;
}

.boxcomponent .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    right: -27px !important;
    border: 2px solid  rgba(11, 38, 99, 1) !important;
    top: 4px !important;
    bottom: 4px !important;    
}

.boxcomponent .css-igs3ac{
    border: 2px solid  rgba(11, 38, 99, 1) !important
}

.boxcomponent .css-p0rm37{
    top: -5px !important;
}

.boxcomponent .css-1x5jdmq{
    padding: 10.5px 14px !important;
}

.slide-list .css-7ozjwe{
    display: none !important;
}

.Reorderbackground{
    /* background-image: url(../../../Assets/images/background-10.png); */
    /* background-size: cover; */
    width: 70%;
}


.MuiTextField-root{
   width: 100% !important;
}

.css-1ty026z{
    padding: 0px 24px !important;
}

/* .reorder-text
{
    margin-left: 310px;
    border: 1px solid #0F2C67;
    padding: 0px 8px 0px 8px;
    font-size: medium;
    border-radius: 4px;
} */

@media screen and (min-width:300px) and (max-width:1000px) {
    .tabs-1{
        height: 90vh;
        /* background-color: rgba(66, 185, 197, 1); */
    }
}

.right-div .css-1rwt2y5-MuiButtonBase-root-MuiButton-root{
   border:2px solid  rgba(11, 38, 99, 1) !important;
}

.right-div .css-79xub{
    border:2px solid  rgba(11, 38, 99, 1) !important;
}

.boxcomponent .css-1ao5qxe{
    color: rgba(11, 38, 99, 1) !important;
}

.background-2{
    height: 75vh;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
}
