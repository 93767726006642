.Logout-section-wrapper .account-menu-button {
    border: none;
    background: transparent;
    color: white;
    text-transform: capitalize;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: right;
  }
  
  .Logout-section-wrapper .account-menu-button:hover {
    border: none;
    background: transparent;
    color: white;
    text-transform: capitalize;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: right;
  }
  
  .profile-name-text {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #000000;
  }
  
  .profile-role-text {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
  }
  
  /* .css-uxbhwt-JoyMenu-root {
    padding: 0px !important;
    border: none !important;
  } */
  
  @media (min-width: 600px) {
    .Logout-section-wrapper .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
      min-height: auto;
    }
  }
  
  .section{
      border: none !important;
      border-radius: 15px !important;
  } 

  /* .css-zczlte:hover{
    background-color: white !important;
  } */