.icon-1{
    height:40px;
    display: flex;
    width:100%;
    justify-content: center;
    align-items: center;
    background-color: #576F91;
}

.notes-1{
    position: sticky;
    transition: width 5s, height 5s;
}

.hoverText{
    /* display: none; */
}

.notes-1:hover .hoverText{
    /* display: inline; */

}

.notes-1:hover{
    width: 300px;
    height: 300px;
}

.notes-expand{
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 60px;
    height: 40px;
     /* background: rgba(53, 53, 53, 0.47); */
    /* backdrop-filter: saturate(180%) blur(20px); */
    border-radius: 4px;
    transition-property: right, height;
    transition: 0.4s ease;
    overflow: hidden;
    right: 3% ;
    top: 10%;
    
}

.notes-header{
    height:40px;
    display: flex;
    width:100%;
    justify-content: center;
    align-items: center;
    background-color:rgb(98, 175, 123);
}

.content-notes{
    height: 0px;
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    background-color: #ffffff;
}

.notes-expand:hover{
    box-shadow: 3px #1d1d1f;
    height:450px;
    width: 300px;
}
.notes-expand:focus{
    box-shadow: 3px #1d1d1f;
    height:450px;
    width: 300px;
}
.notes-expand::-webkit-scrollbar
{
    margin-top: 5px;
    width: 5px;
    height: 3px;
    background-color: #F5F5F5;
}
.notes-expand::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    background-color: #1d1d1f
}


.content-notes li {
    font-size: 13px;
    padding: 8px;
    display: flex;

}

ol{
    padding:0px;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1d1d1f; 
}