.button-text-1 {
    display: inline-block;
    border-radius: 4px;
    background-color:#2ecc71;
    border: none;
    min-width: 100px;
    color: #fff;
    text-align: center;
    font-size: 15px;
    padding: 5px 10px;
    width: auto;
    transition: all .5s;
    cursor: pointer;
    margin: 5px;
    margin-left: 500px;
}

.headers{
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
}

.header-4{
    margin-left: 38%;
    font-size: 20px;
    font-weight: 500;
}

.header-3{
    margin-left: 47%;
    font-size: 20px;
    font-weight: 500;
}