.slidebar
{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}

#tab
{  
    /* display: flex;
    justify-content: center; */
    color: white;
    font-size: 13px !important;
    font-weight: 700 !important;
    margin-top: 3px;
    margin-bottom: 3px;
}

.tap{
    /* background-color: #1B336D !important; */
    color: #271160 !important;
    /* padding-left: 15px !important;
    padding-right: 15px !important */
    font-size: 15px !important;
    font-weight: 700 !important;
}

.slide-list{
    display: block;
    overflow-y: scroll;
    padding-bottom: 12px;
    flex-grow: 1;
    width: 100%;
    /* max-width: 220px; */
    height: auto;
    /* // background-color: #3bb78f;
    // background-image: linear-gradient(315deg, #3bb78f 0%, #0bab64 74%); */
}

.slide-div{
    /* // margin-left: auto; */
    margin-right: auto;
    margin-bottom: 10px;
    height: 110px;
    width: 200px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* display: block; */
    border: 2px solid #0F2C67;
    /* border-right: 10px solid #0F2C67; */
}
.slide-div:focus{
    border: 1px solid #ce2c32;
}

.slide-presentation{
    padding: 2px 0px;
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
}
.slide-open{
    /* // height: 370px; */
    width:100%;
    /* // max-width: 650px;
    // margin: 5px 0px; */
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    /* // background-color: #3bb78f;
/* // background-image: linear-gradient(315deg, #3bb78f 0%, #0bab64 74%);
    // border: 2px solid #0F2C67; */
}

.tab-Style{
    padding-left: 15px !important;
    padding-right: 15px !important
}

.border-effect{
    border: 0px !important;
}

.Pagination_Padding{
    padding: 0px !important;
}

.css-19kzrtu {
    padding: 0px !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    /* padding: 5px !important; */
}   

.css-1t4lqmc-MuiButtonBase-root-MuiTab-root {

    padding-top: 7px !important;
    /* padding-left: 50px !important; */
    padding-bottom: 0px !important;
}


.tab_widthstyle{
    width: 220px !important;
    height: 81vh;
    /* display: flex !important; */
    /* background-color: rgba(66, 185, 197, 1); */
    background-image: url(../../../Assets/images/Rectangle-34.png);
}

.background-1{
   height: 84%;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
}

.css-weapcg-MuiPaper-root-MuiAppBar-root {

    box-shadow: none !important
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    border-radius: 0px !important;
    padding: 0px !important;
}

.css-zun73v.Mui-checked, .css-zun73v.MuiCheckbox-indeterminate{
    color: #e83e8c !important;
}

.stack .css-a3xx3e-MuiButtonBase-root-MuiButton-root
{
    margin-right: 0px !important;
}


.css-1anhkrs{
    display: -webkit-box !important
}



.header-tabs .css-2swjet{
    min-height: 0px !important;
    padding: 6px;
}

.css-23pgmv{
    display: none !important;
}

.css-ttwr4n{
    display: none !important;
}

.css-11hvlkh{
   transition: none !important;
   box-shadow: none !important;
   background-image: none !important;
}

.css-zun73v{
    border-radius: 0% !important;
    padding: 0px !important;
}

.css-7ozjwe{
    display: none !important;
}
.react-swipeable-view-container > div {
    overflow: hidden !important;
}

button#vertical-tab-0 {
    cursor: unset;
}

.bg .css-heg063-MuiTabs-flexContainer{
    display: flex;
    width: auto;
    justify-content: center;
    background-image: url(../../../Assets/images/background-10.png);
    /* background-color: #40ebe083 !important ; */
}

.bg .css-k008qs{
    display: flex;
    justify-content: center;
    background-image: url(../../../Assets/images/background-10.png);
}

.tabpanel{
    background-color: #0F2C67;
}

.tabshowbackground{
    /* background-image: url(../../../Assets/images/background-10.png); */
    background-size: cover;
    width: 95%;
}

.titleshowbackground{
    /* background-image: url(../../../Assets/images/Rectangle-34.png); */
    background-size: cover;
    width: 95%;
}

/* .bg{
    background-color: rgba(66, 185, 197, 1)!important ;
} */

@media screen and (max-width:1000px){
    .bg .css-heg063-MuiTabs-flexContainer{
        display: flex;
        width: auto;
        justify-content: start;
        /* background-image: url(../../../Assets/images/Rectangle-1.png); */
        /* background-color: #40ebe083 !important ; */
    }

    .bg .css-k008qs{
        display: flex;
        justify-content: start;
    }
}

.outerbox{
    border: 1px solid #CFD9EC;
    height: 37px;
    width: auto;
    background-color: white;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
}

/* @media screen and (max-width:1280px){
    .outerbox{
        border: 1px solid #CFD9EC;
        height: 45px;
        width: 17%;
        background-color: white;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 25px;
    }
} */

/* @media screen and (max-width:1024px){
    .outerbox{
        border: 1px solid #CFD9EC;
        height: 45px;
        width: 23%;
        background-color: white;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 25px;
    }
} */

/* @media screen and (max-width:912px){
    .outerbox{
        border: 1px solid #CFD9EC;
        height: 45px;
        width: 25%;
        background-color: white;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 25px;
    }
} */



/* @media screen and (max-width:820px){
    .outerbox{
        border: 1px solid #CFD9EC;
        height: 45px;
        width: 28%;
        background-color: white;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 25px;
    }
} */

/* @media screen and (max-width:768px){
    .outerbox{
        border: 1px solid #CFD9EC;
        height: 45px;
        width: 30%;
        background-color: white;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 25px;
    }
} */