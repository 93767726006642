.brand
{
    font-size: 18px;
    color: #ffffff;
    font-weight: 500;
}

.title
{
    margin-left: 100px !important;
}

.image 
{
    margin-right: 20px !important;
}

.Pre-Sales{
display: block;
    font-size: 24px;
    font-weight: 500;
    color: #1C658C;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: white;
    outline: 0;
    color: black;
}